import { useTranslation } from 'react-i18next'
import FeedbackWidget from 'components/FeedbackWidget/FeedbackWidget.tsx'
import { WidgetSlug } from 'types/feedbackWidget.ts'
import useOpenFeedbackWidget from 'hooks/useOpenFeedbackWidget.ts'
import { useGetYearOverviewMetricsQuery } from 'store/queries/bolt-api/metrics'
import SectionPersonal from 'components/EnergyOverview/SectionPersonal.tsx'
import SectionCommunity from 'components/EnergyOverview/SectionCommunity.tsx'
import Card from 'components/Card/Card.tsx'
import styles from 'pages/App/energy-overview/EnergyOverview.module.scss'
import LoadingCard from 'components/LoadingCard/LoadingCard.tsx'
import { useState } from 'react'
import { selectContact, selectCurrentContracts } from 'store/contact/selectors.ts'
import { useStoreSelector } from 'hooks/store.ts'
import PageLayout from 'layouts/page-layout/PageLayout.tsx'
import { FaqSlug } from 'types/faq.ts'

const EnergyOverview = () => {
  // REDUX STORE
  const contactStore = useStoreSelector((store) => store.contact)

  // Contracts
  const billingContract = selectCurrentContracts(contactStore)
  const { email } = selectContact(contactStore)

  // Redux Query
  const { data: yearOverviewData, isLoading } = useGetYearOverviewMetricsQuery({
    email,
    billingContractNumber: billingContract.contractNumber
  })

  // i18n
  const { t } = useTranslation('energyOverview')

  // Local state
  const [isFeedbackWidgetOpen, setIsFeedbackWidgetOpen] = useState<boolean>(false)

  // Custom hook
  const { openFeedbackWidget, feedbackWidgetData } = useOpenFeedbackWidget({
    slug: WidgetSlug.ENERGY_OVERVIEW,
    startTimer: isFeedbackWidgetOpen
  })

  return (
    <>
      <PageLayout title={t('nav.energyOverview', { ns: 'common' })} faqCategories={[FaqSlug.ENERGY_OVERVIEW]}>
        <div className={styles.container}>
          {isLoading ? (
            <>
              <LoadingCard key="personal-card" />

              <LoadingCard key="community-card" />
            </>
          ) : yearOverviewData ? (
            <>
              <Card as="section" padding={0} className={styles['slider-card']}>
                <SectionPersonal
                  openFeedbackWidget={(state) => setIsFeedbackWidgetOpen(state)}
                  data={yearOverviewData.yearOverviewMetrics.personal}
                  communityReferrals={yearOverviewData.yearOverviewMetrics.community.referrals.total || 0}
                />
              </Card>

              <Card as="section" padding={0} className={styles['slider-card']}>
                <SectionCommunity
                  openFeedbackWidget={(state) => setIsFeedbackWidgetOpen(state)}
                  data={yearOverviewData.yearOverviewMetrics.community}
                />
              </Card>
            </>
          ) : (
            <Card className={styles['slider-card']}>
              <Card.Title>{t('personal.title')}</Card.Title>
              <div className={styles.placeholder}>
                <p>{t('personal.noData')}</p>
              </div>
            </Card>
          )}
        </div>
      </PageLayout>

      {/* FeedbackWidget */}
      {feedbackWidgetData?.isActive && <FeedbackWidget id={feedbackWidgetData._id} openFeedbackWidget={openFeedbackWidget} />}
    </>
  )
}

export default EnergyOverview
